<template>
    <v-app-bar fixed color="#5a77b3" elevation-1 style="z-index: 1;">
        <!-- elevate-on-scroll scroll-target="#scrolling-techniques-7" -->
        <v-app-bar-nav-icon v-if="!$store.state.fullSize" color="white" @click="toogleDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">
            <!-- <v-btn icon color="white">
                <v-icon>menu</v-icon>
            </v-btn> -->
            <v-img max-width="220" contain class="" max-height="40" :src="logoCommunityWare"></v-img></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="logout">
            <v-icon color="white">logout</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
export default {
    methods: {
        async getMenuItens() {
            var obj = {
                noLoader: true,
                url: "/api/v1/subscriptionAPI/contract/partners/status",
                // query: { partner_id: localStorage.getItem("partner_id") },
                query: null,
                method: "get",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                let permissions = [false, false];
                for (let i = 0; i < response.data.length; i++) {
                    if (
                        response.data[i].prefix == "dtvgo" && response.data[i].status == true
                        //response.data[i].contract_type != "Company contract"
                    ) {
                        permissions[0] = true;
                    }
                    if (
                        response.data[i].prefix == "pagamento" && response.data[i].status == true
                        //response.data[i].contract_type != "Company contract"
                    ) {
                        permissions[1] = true;
                    }
                }
                this.$store.commit(
                    "clientPermissions/setProductsPermissions",
                    response.data
                );
            })
        },
        async getPermissions(serviceId) {
            let query = {
                // localStorage.getItem("partner_id")
                //service_id: serviceId,
                prefix: "dtvgo",
                user_id: localStorage.getItem("userId"),
            };
            var obj = {
                url: "/api/partner/contact/permissions/get-all",
                query: query,
                method: "post",
                noLoader: true
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                let obj = { ...response.data }
                let allFalse = true
                for (var i in obj) {
                    if (obj[i] === true) {
                        allFalse = false;
                        break;
                    }
                }
                if (!allFalse) {
                    obj.hasProd = true
                } else {
                    obj.hasProd = false
                }
                this.$store.commit(
                    "clientPermissions/updateNewAreaPermissions",
                    { name: "dtvgo", val: obj }
                );
            });
        },
        toogleDrawer() {
            // console.log('1')
            this.$store.commit("toogleClientDrawerMenu", null);
        },
        logout() {
            const _this = this;
            let obj = {
                url: "/api/logout",
                query: { user_id: this.closeId },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.$store.dispatch("setCookie", {
                    cname: "loginUrl",
                    cvalue: "",
                    exdays: 1,
                });
                if (response) {
                    localStorage.clear();
                    _this.$router.push("/");
                }
            });
            return true;
        },
    },
    mounted() {
        this.getMenuItens().then(_ => {
            this.getPermissions()
        })
    },
    data() {
        return {
            logoCommunityWare: require("@/assets/newArea/logo-community-ware.png"),
        }
    },
    computed: {
        closeId() {
            return localStorage.getItem("userId");
        },
        userName() {
            return localStorage.getItem("userName")
                ? localStorage.getItem("userName")
                : "Aguardando nome do usuário";
        },
    },
}
</script>

<style lang="scss" scoped></style>