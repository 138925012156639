<template>
    <!-- <v-navigation-drawer style="min-width: 285px;" permanent width="285"> -->
    <v-navigation-drawer :style="$store.state.fullSize ? 'min-width: 285px;' : ''"
        v-model="$store.state.newClientDrawerMenu" :absolute="!$store.state.fullSize"
        :temporary="!$store.state.fullSize" :permanent="$store.state.fullSize" width="285">
        <v-list-item :class="['drawerOpen', 'py-5']">
            <v-list-item-avatar color="white" size="56">
                <span class="text-h5">{{ initialsAvatar }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="text-h6 white--text">
                    {{ nameView }}
                </v-list-item-title>
                <v-list-item-subtitle class="white--text font-italic">
                    {{ emailView }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav class="primaryNew--text">
            <v-list-item @click="openExPanel('home')" active-class="selItem" class="" link value="home"
                to="/cliente/home">
                <v-list-item-icon>
                    <v-icon color="primaryNew">mdi-home-circle-outline</v-icon>
                </v-list-item-icon><v-list-item-content>
                    <v-list-item-title class="primaryNew--text">Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-expansion-panels v-if="$store.state.clientPermissions.newAreapermissions.pagamento.hasProd" class=""
                v-model="stateExPanel" flat>
                <v-expansion-panel class="menuOpened">
                    <v-expansion-panel-header style="min-height: 44px;" class="pa-0 align-start" hide-actions>
                        <v-list-item @click="openExPanel(0)" active-class="selItem" class="primaryNew--text pgtos" link
                            to="/cliente/pagamentos">
                            <v-list-item-icon>
                                <v-icon color="primaryNew">payment</v-icon>
                            </v-list-item-icon><v-list-item-content>
                                <v-list-item-title class="primaryNew--text">Pagamentos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="menuBorderRadius">
                        <!-- color="#f6f6f6" -->
                        <v-list dense>
                            <v-subheader class="primaryNew--text font-weight-medium subtitle-1">Boletos</v-subheader>
                            <v-list-item class="adm-boletos" color="primaryNew" link
                                to="/cliente/pagamentos/adm-boletos">
                                <v-list-item-icon class="mr-3">
                                    <v-icon color="primaryNew" size="20">local_atm</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="primaryNew--text">Transações</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="config-boletos" color="primaryNew" link
                                to="/cliente/pagamentos/config-boletos">
                                <v-list-item-icon class="mr-3">
                                    <v-icon color="primaryNew" size="20">settings</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="primaryNew--text">Configuração</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-subheader class="primaryNew--text font-weight-medium subtitle-1">Cartões de
                                crédito</v-subheader>
                            <v-list-item class="adm-cartoes" color="primaryNew" link
                                to="/cliente/pagamentos/adm-cartoes">
                                <v-list-item-icon class="mr-3">
                                    <v-icon color="primaryNew" size="20">credit_card</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="primaryNew--text">Transações</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="config-cartoes" color="primaryNew" link
                                to="/cliente/pagamentos/config-cartoes">
                                <v-list-item-icon class="mr-3">
                                    <v-icon color="primaryNew" size="20">settings</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="primaryNew--text">Configuração</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-list-item @click="openExPanel('contracts')" active-class="selItem" class="primaryNew--text" link
                to="/cliente/meus-contratos">
                <v-list-item-icon>
                    <v-icon color="primaryNew">mdi-file-sign</v-icon>
                </v-list-item-icon><v-list-item-content>
                    <v-list-item-title class="primaryNew--text">Meus contratos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="openExPanel('financeira')" active-class="selItem" class="primaryNew--text" link
                to="/cliente/area-financeira">
                <v-list-item-icon>
                    <v-icon color="primaryNew">mdi-chart-timeline-variant-shimmer</v-icon>
                </v-list-item-icon><v-list-item-content>
                    <v-list-item-title class="primaryNew--text">Área financeira</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="toogleCerdentialsDilog" active-class="selItem" class="primaryNew--text" link value="">
                <v-list-item-icon>
                    <v-icon color="primaryNew">mdi-dev-to</v-icon>
                </v-list-item-icon><v-list-item-content>
                    <v-list-item-title class="primaryNew--text">Área do desenvolvedor</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <Credentials :toogleCerdentialsDilog="toogleCerdentialsDilog" :cerdentialsDilog="cerdentialsDilog" />
    </v-navigation-drawer>
</template>
<script>
import Credentials from "../../components/clients/credentials/Credentials.vue";
export default {
    watch: {
        stateExPanel(v) {
            // console.log('wtcompar', v)

        },
        screenSize(v) {
            // console.log('wtch', v)
            this.$store.commit("defineFullSize", v);
        }
    },
    methods: {
        openExPanel(menuItem) {
            // console.log('compare')
            if (menuItem == 0) {
                this.stateExPanel = [0]
                return
            } else {
                this.stateExPanel = null
            }
        },
        toogleCerdentialsDilog() {
            this.cerdentialsDilog = !this.cerdentialsDilog;
        },
        getUserInfo() {
            let name = localStorage.getItem("userName")
            let email = localStorage.getItem("userEmail")
            this.nameView = name
            this.emailView = email
            this.initialsAvatar = name.substring(0, 2).toUpperCase()
        }
    },
    mounted() {
        // console.log('computDrawer', this.$router.currentRoute.path)
        let urlSel = this.$router.currentRoute.path
        let splitUrl = urlSel.split("/")
        let classToclick = splitUrl[splitUrl.length - 1]
        let paymentArea = urlSel.indexOf("cliente/pagamentos")
        if (paymentArea >= 0) {
            setTimeout(() => {
                document.getElementsByClassName('selItem')[0].click()
                setTimeout(() => {
                    if (document.getElementsByClassName(classToclick).length) {
                        document.getElementsByClassName(classToclick)[0].click()
                    }
                }, 10);
            }, 500);
        }
        this.getUserInfo();
        this.$store.commit("defineFullSize", this.screenSize);
    },
    data() {
        return {
            stateExPanel: [],
            cerdentialsDilog: false,
            drawerMenu: true,
            nameView: "",
            emailView: "",
            initialsAvatar: ""
        }
    },
    computed: {
        screenSize() {
            let size = this.$vuetify.breakpoint.width
            // console.log(size)
            return size
        }
    },
    props: {
        // rail: Boolean
    },
    components: {
        Credentials,
    },
}
</script>
<style lang="scss" scoped>
.drawerClose {
    background-color: #6e29d8;
}

.selItem {
    background-color: #5a77b3 !important;
    color: white !important;
    font-weight: 500 !important;
    box-shadow: 0px 1px 3px rgb(100, 100, 100);
}

.selItem i,
.selItem .v-list-item__title {
    color: white !important;
    font-weight: 500 !important;
}

.drawerOpen {
    background-image: url('../../assets/newArea/drawer-img-02.jpg');
    background-position: center center;
    background-size: cover;
    display: block;
}

.menuOpened .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
}

.menuBorderRadius {
    border-radius: 0 0 5px 5px;
    box-shadow: inset #ccc 0px 0px 20px -5px;
}
</style>