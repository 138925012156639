<template>
  <v-dialog scrollable light v-model="cerdentialsDilog" persistent width="740">
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="mb-5 headline px-2">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0">
            Minhas credênciais
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn @click="toogleCerdentialsDilog" class="mt-7" color="red" fab icon dark x-small absolute top right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar>
      </v-card-title>

      <v-card-text class="Caption grey--text text--darken-1 pb-0">
        <span>Na lista abaixo você encontra as suas credênciais de autenticação via
          ERP, e com elas você consegue ter acesso aos seus dados utilizando as
          APIs do Marketplace.</span>
      </v-card-text>
      <v-card-text class="mt-8 mb-15">
        <v-data-table class="credentialsTable" :headers="headers" :items="credentials" :items-per-page="5"
          hide-default-footer no-data-text="Você ainda não possui nenhuma credêncial de autenticação de ERP cadastrada."
          no-results-text="O filtro atual não retornou nenhum resultado">
          <template v-slot:item.client_id="{ item }">
            <h3 class="py-5 grey--text text--darken-2">
              <span class="grey--text text--darken-2">{{ item.client_id }}</span>
            </h3>
          </template>
          <template v-slot:item.client_secret="{ item }">
            <h3 class="py-5 grey--text text--darken-2">
              <span class="grey--text text--darken-2">{{ item.client_secret }}</span>
            </h3>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="px-1 mr-3" @click="copyTxt(item.client_secret)" fab x-small
                  color="primaryNew" dark><v-icon center small> content_copy </v-icon></v-btn>
              </template>
              <span class="miniTtip">Copiar Client secret</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleCerdentialsDilog"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { toogleCerdentialsDilog: Function, cerdentialsDilog: Boolean },
  data() {
    return {
      windowH: "580",
      credentials: [],
      headers: [
        {
          text: "Client ID",
          value: "client_id",
          align: "start",
          sortable: false,
        },
        {
          text: "Client secret",
          value: "client_secret",
          align: "start",
          sortable: false,
        },
        { text: "Opções", value: "actions", sortable: false },
      ],
    };
  },
  beforeMount() {
    //this.getHeightPage();
  },
  mounted() {
    this.getCredentials();
  },
  methods: {
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTxt(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      let _this = this;
      navigator.clipboard.writeText(text).then(
        function () {
          _this.$store.commit("showAlert", {
            message: "Texto copiado para a área de transferência!",
            type: "success",
          });
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    // copyTxt(txt) {
    //   let copyText = txt;
    //   document.execCommand("copy");
    //   this.$store.commit("showAlert", {
    //     message: "Texto copiado para a área de transferência com sucesso!",
    //     type: "success",
    //   });
    //   //alert("Copied the text: " + copyText);
    // },
    getCredentials() {
      var obj = {
        url: "/api/v1/subscriptionAPI/partner/getCredentials",
        // query: { id: localStorage.getItem("partner_id") },
        noLoader: true,
        query: null,
        method: "get",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log("console");

        if (Array.isArray(response.data)) {
          this.credentials = [...response.data];
        } else {
          this.credentials.push(response.data);
        }
        // this.credentials.map((sub) => {
        //   sub.price = sub.subscription_content.length
        //     ? sub.subscription_content[0].value
        //     : 0.0;
        // });
      });
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125 - 270;
    },
  },
};
</script>

<style>
.v-data-table__empty-wrapper td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.credentialsTable td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
</style>