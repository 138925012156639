<template>
    <div class="d-flex flex-column" style="height: 100%; padding-top: 64px;">
        <HeaderClient class="flex-grow-0" />
        <div class="d-flex flex-row flex-grow-1">
            <DrawerMenu />
            <router-view style="max-width: 1920px;" class="flex-grow-1"></router-view>
        </div>
        <!-- <v-divider></v-divider> -->
        <v-footer padless class="flex-grow-0">
            <v-card class="flex primaryNew" flat tile>
                <v-card-text class="py-2 white--text text-center">
                    © {{ new Date().getFullYear() }} Copyright — <strong>Community Ware</strong>
                </v-card-text>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
import HeaderClient from "../../newArea/components/clientArea/ClientHeader.vue"
import DrawerMenu from "../../newArea/common/DrawerMenu.vue"
export default {
    components: {
        HeaderClient,
        DrawerMenu,
    },
}
</script>

<style lang="scss" scoped></style>