var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","light":"","persistent":"","width":"740"},model:{value:(_vm.cerdentialsDilog),callback:function ($$v) {_vm.cerdentialsDilog=$$v},expression:"cerdentialsDilog"}},[_c('v-card',[_c('v-card-title',{staticClass:"mb-5 headline px-2",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_vm._v(" Minhas credênciais ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-7",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleCerdentialsDilog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"Caption grey--text text--darken-1 pb-0"},[_c('span',[_vm._v("Na lista abaixo você encontra as suas credênciais de autenticação via ERP, e com elas você consegue ter acesso aos seus dados utilizando as APIs do Marketplace.")])]),_c('v-card-text',{staticClass:"mt-8 mb-15"},[_c('v-data-table',{staticClass:"credentialsTable",attrs:{"headers":_vm.headers,"items":_vm.credentials,"items-per-page":5,"hide-default-footer":"","no-data-text":"Você ainda não possui nenhuma credêncial de autenticação de ERP cadastrada.","no-results-text":"O filtro atual não retornou nenhum resultado"},scopedSlots:_vm._u([{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"py-5 grey--text text--darken-2"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(item.client_id))])])]}},{key:"item.client_secret",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"py-5 grey--text text--darken-2"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(item.client_secret))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1 mr-3",attrs:{"fab":"","x-small":"","color":"primaryNew","dark":""},on:{"click":function($event){return _vm.copyTxt(item.client_secret)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","small":""}},[_vm._v(" content_copy ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Copiar Client secret")])])]}}])})],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleCerdentialsDilog}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }